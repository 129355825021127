import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  Tab,
  TabbedShowLayout,
  DeleteButton,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceManyField,
  SelectInput,
  choices,
  ArrayField,
  NumberField,
  SingleFieldList,
  ChipField
} from 'react-admin'

const validateName = [required(), minLength(1)]
const validateUrl = [minLength(5)]
const validateDiscountType = [required(), choices(['percent', 'amount'])]

const MenuFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
  </Filter>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
)

export const MenuList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<MenuFilter />}>
    <Datagrid>
      <TextField source='name' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const MenuShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label='menu'>
          <TextField source='name' />
          <TextField source='subtitle' />
          <TextField source='description' />
        </Tab>
        <Tab label='menu-items' path='menu-items'>
          <ArrayField source='items' sortable={false}>
            <Datagrid>
              <TextField source='name' />
              <TextField source='subtitle' />
              <TextField source='description' />
              <NumberField source='price' />
              <NumberField source='nutritionalValue.kj' />
              <NumberField source='nutritionalValue.kcal' />
              <NumberField source='nutritionalValue.fat' />
              <NumberField source='nutritionalValue.saturatedFat' />
              <NumberField source='nutritionalValue.carbohydrates' />
              <NumberField source='nutritionalValue.sugars' />
              <NumberField source='nutritionalValue.protein' />
              <NumberField source='nutritionalValue.salt' />
              <NumberField source='nutritionalValue.kjPortion' />
              <NumberField source='nutritionalValue.kcalPortion' />
              <NumberField source='nutritionalValue.fatPortion' />
              <NumberField source='nutritionalValue.saturatedFatPortion' />
              <NumberField source='nutritionalValue.carbohydratesPortion' />
              <NumberField source='nutritionalValue.sugarsPortion' />
              <NumberField source='nutritionalValue.proteinPortion' />
              <NumberField source='nutritionalValue.saltPortion' />
              <TextField source='discount.type' />
              <NumberField source='discount.value' />
              <ReferenceManyField label='allergies' reference='allergies' target='menuItemId'>
                <SingleFieldList>
                  <ChipField source='name' />
                </SingleFieldList>
              </ReferenceManyField>
              <ReferenceManyField label='labels' reference='labels' target='menuItemId'>
                <SingleFieldList>
                  <ChipField source='name' />
                </SingleFieldList>
              </ReferenceManyField>
              <ReferenceManyField label='featured-images' reference='featured-images' target='menuItemId'>
                <SingleFieldList>
                  <ChipField source='url' />
                </SingleFieldList>
              </ReferenceManyField>
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label='featured-images' path='featured-images'>
          <ReferenceManyField reference='featured-images' label='' target='menuId'>
            <Datagrid>
              <TextField source='url' />
              <TextField source='alt' />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
)

export const MenuEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TabbedShowLayout style={{ width: '100%' }}>
        <Tab label='menu'>
          <TextInput fullWidth source='name' validate={validateName} />
          <TextInput fullWidth source='subtitle' />
          <TextInput fullWidth source='description' validate={validateName} />
        </Tab>
        <Tab label='menu-items' path='menu-items'>
          <ArrayInput fullWidth source='items'>
            <SimpleFormIterator>
              <TextInput fullWidth source='name' validate={validateName} />
              <TextInput fullWidth source='subtitle' />
              <TextInput fullWidth source='description' validate={validateName} />
              <NumberInput fullWidth source='price' />
              <ReferenceArrayInput fullWidth source='allergyIds' reference='allergies'>
                <SelectArrayInput optionText='name' />
              </ReferenceArrayInput>
              <ReferenceArrayInput fullWidth source='labelIds' reference='labels'>
                <SelectArrayInput optionText='name' />
              </ReferenceArrayInput>
              <NumberInput fullWidth source='nutritionalValue.kj' />
              <NumberInput fullWidth source='nutritionalValue.kcal' />
              <NumberInput fullWidth source='nutritionalValue.fat' />
              <NumberInput fullWidth source='nutritionalValue.saturatedFat' />
              <NumberInput fullWidth source='nutritionalValue.carbohydrates' />
              <NumberInput fullWidth source='nutritionalValue.sugars' />
              <NumberInput fullWidth source='nutritionalValue.protein' />
              <NumberInput fullWidth source='nutritionalValue.salt' />
              <NumberInput fullWidth source='nutritionalValue.kjPortion' />
              <NumberInput fullWidth source='nutritionalValue.kcalPortion' />
              <NumberInput fullWidth source='nutritionalValue.fatPortion' />
              <NumberInput fullWidth source='nutritionalValue.saturatedFatPortion' />
              <NumberInput fullWidth source='nutritionalValue.carbohydratesPortion' />
              <NumberInput fullWidth source='nutritionalValue.sugarsPortion' />
              <NumberInput fullWidth source='nutritionalValue.proteinPortion' />
              <NumberInput fullWidth source='nutritionalValue.saltPortion' />
              <SelectInput
                fullWidth
                source='discount.type'
                choices={[
                  { id: 'percent', name: 'Percent' },
                  { id: 'amount', name: 'Amount' }
                ]}
                validate={validateDiscountType}
              />
              <NumberInput fullWidth source='discount.value' />
              <ArrayInput fullWidth source='featuredImages'>
                <SimpleFormIterator>
                  <TextInput fullWidth source='url' validate={validateUrl} />
                  <TextInput fullWidth multiline source='alt' />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
        <Tab label='featured-images' path='featured-images'>
          <ArrayInput fullWidth source='featuredImages'>
            <SimpleFormIterator>
              <TextInput fullWidth source='url' validate={validateUrl} />
              <TextInput fullWidth multiline source='alt' />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Edit>
)
