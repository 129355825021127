import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  Tab,
  TabbedShowLayout,
  DeleteButton,
  ArrayInput,
  SimpleFormIterator,
  ReferenceManyField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField
} from 'react-admin'

const validateName = [required(), minLength(1)]
const validateUrl = [minLength(5)]

const RestaurantFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
    <TextInput source='organizationNumber' alwaysOn />
  </Filter>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
)

export const RestaurantList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<RestaurantFilter />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='organizationNumber' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const RestaurantShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label='restaurant'>
          <TextField source='name' />
          <TextField source='organizationNumber' />
          <TextField source='url' />
          <TextField source='street' />
          <TextField source='zipcode' />
          <TextField source='city' />
          <TextField source='logoUrl' />
          <ReferenceField label='location' source='locationId' reference='locations'>
            <TextField source='name' />
          </ReferenceField>
        </Tab>
        <Tab label='menu' path='menus'>
          <ReferenceManyField reference='menus' label='' target='restaurantId'>
            <Datagrid>
              <TextField source='name' />
              <TextField source='subtitle' />
              <TextField source='description' />
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label='featured-images' path='featured-images'>
          <ReferenceManyField reference='featured-images' label='' target='restaurantId'>
            <Datagrid>
              <TextField source='url' />
              <TextField source='alt' />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
)

export const RestaurantEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TabbedShowLayout style={{ width: '100%' }}>
        <Tab label='restaurant'>
          <TextInput fullWidth source='name' validate={validateName} />
          <TextInput fullWidth source='organizationNumber' />
          <TextInput fullWidth source='url' />
          <TextInput fullWidth source='street' />
          <TextInput fullWidth source='zipcode' />
          <TextInput fullWidth source='city' />
          <TextInput fullWidth source='logoUrl' />
          <ReferenceInput
            source='locationId'
            reference='locations'
            sort={{ field: 'city', order: 'ASC' }}
            matchSuggestion={() => true}
            perPage={100}
          >
            <AutocompleteInput optionText={record => record.name || record.city ? `${record.city} - ${record.name}` : ''} />
          </ReferenceInput>
        </Tab>
        <Tab label='menus' path='menus'>
          <ArrayInput fullWidth source='menus'>
            <SimpleFormIterator>
              <TextInput fullWidth source='name' validate={validateName} />
              <TextInput fullWidth source='subtitle' />
              <TextInput fullWidth source='description' validate={validateName} />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
        <Tab label='featured-images' path='featured-images'>
          <ArrayInput fullWidth source='featuredImages'>
            <SimpleFormIterator>
              <TextInput fullWidth source='url' validate={validateUrl} />
              <TextInput fullWidth multiline source='alt' />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Edit>
)

export const RestaurantCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TabbedShowLayout style={{ width: '100%' }}>
        <Tab label='restaurant'>
          <TextInput fullWidth source='name' validate={validateName} />
          <TextInput fullWidth source='organizationNumber' />
          <TextInput fullWidth source='url' />
          <TextInput fullWidth source='street' />
          <TextInput fullWidth source='zipcode' />
          <TextInput fullWidth source='city' />
          <TextInput fullWidth source='logoUrl' />
          <ReferenceInput
            source='locationId'
            reference='locations'
            sort={{ field: 'city', order: 'ASC' }}
            matchSuggestion={() => true}
            perPage={100}
          >
            <AutocompleteInput optionText={record => record.name || record.city ? `${record.city} - ${record.name}` : ''} />
          </ReferenceInput>
        </Tab>
        <Tab label='menus' path='menus'>
          <ArrayInput fullWidth source='menus'>
            <SimpleFormIterator>
              <TextInput fullWidth source='name' validate={validateName} />
              <TextInput fullWidth source='subtitle' />
              <TextInput fullWidth source='description' validate={validateName} />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
        <Tab label='featured-images' path='featured-images'>
          <ArrayInput fullWidth source='featuredImages'>
            <SimpleFormIterator>
              <TextInput fullWidth source='url' validate={validateUrl} />
              <TextInput fullWidth multiline source='alt' />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Create>
)
