import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  NumberInput,
  TextField,
  PasswordInput,
  Show,
  required,
  email,
  number,
  maxValue,
  minValue,
  ShowButton,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
  FunctionField
} from 'react-admin'

const validateRole = [required(), minValue(0), maxValue(10), number()]
const validateEmail = [required(), email()]
const validatePassword = []

const UserFilter = props => (
  <Filter {...props}>
    <TextInput source='email' alwaysOn />
    <TextInput source='firstName' alwaysOn />
    <TextInput source='lastName' alwaysOn />
  </Filter>
)

export const UserList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<UserFilter />}>
    <Datagrid>
      <TextField source='email' />
      <TextField source='role' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const UserShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label='user'>
        <TextField source='email' />
        <TextField source='firstName' />
        <TextField source='lastName' />
        <TextField source='phone' />
        <TextField source='role' />
      </Tab>
      <Tab label='documents' path='documents'>
        <ReferenceManyField reference='documents' target='userId' label=''>
          <Datagrid>
            <FunctionField render={record => record.documentType.name} />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const UserEdit = props => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        <TextInput source='email' validate={validateEmail} />
        <TextInput source='firstName' />
        <TextInput source='lastName' />
        <TextInput source='phone' />
        <NumberInput source='role' initialValue='0' validate={validateRole} />
        <PasswordInput source='password' validate={validatePassword} />
      </SimpleForm>
    </Edit>
  )
}

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='email' validate={validateEmail} />
      <TextInput source='firstName' />
      <TextInput source='lastName' />
      <TextInput source='phone' />
      <NumberInput source='role' initialValue='0' validate={validateRole} />
      <PasswordInput source='password' validate={validatePassword} />
    </SimpleForm>
  </Create>
)
