import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  Tab,
  TabbedShowLayout,
  ArrayInput,
  SimpleFormIterator,
  ReferenceManyField
} from 'react-admin'

const validateName = [required(), minLength(1)]

const DocumentTypeFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
  </Filter>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const DocumentTypeList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<DocumentTypeFilter />}>
    <Datagrid>
      <TextField source='name' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const DocumentTypeShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label='document-type'>
          <TextField source='name' />
        </Tab>
        <Tab label='document-value-names' path='document-value-names'>
          <ReferenceManyField reference='document-value-names' label='' target='documentTypeId'>
            <Datagrid>
              <TextField source='name' />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
)

export const DocumentTypeEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TabbedShowLayout style={{ width: '100%' }}>
        <Tab label='document-type'>
          <TextInput fullWidth source='name' validate={validateName} />
        </Tab>
        <Tab label='document-value-names' path='documentValueNames' fullWidth>
          <ArrayInput fullWidth source='documentValueNames'>
            <SimpleFormIterator>
              <TextInput fullWidth source='name' validate={validateName} />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Edit>
)

export const DocumentTypeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TabbedShowLayout style={{ width: '100%' }}>
        <Tab label='document-type'>
          <TextInput fullWidth source='name' validate={validateName} />
        </Tab>
        <Tab label='document-value-names' path='documentValueNames' fullWidth>
          <ArrayInput fullWidth source='documentValueNames'>
            <SimpleFormIterator>
              <TextInput fullWidth source='name' validate={validateName} />
            </SimpleFormIterator>
          </ArrayInput>
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Create>
)
