import decodeJwt from 'jwt-decode'

const auth = {
  login: async ({ username, password }) => {
    const response = await window.fetch(`${process.env.REACT_APP_BASE_API_URL}/v1/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: username, password })
    })

    const data = await response.json()

    return new Promise((resolve, reject) => {
      if (response.status < 200 || response.status >= 400) {
        return reject(new Error(response.status))
      }

      let token = ''

      if (data.data && data.data[0] && data.data[0].tokens) {
        token = data.data[0].tokens.authToken
      } else {
        return reject(new Error(response.status))
      }

      const decodedToken = decodeJwt(token)

      if (decodedToken.role >= 5) {
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('role', decodedToken.role)

        return resolve()
      }

      return reject(new Error(response.status))
    })
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      window.localStorage.removeItem('myToken')
      window.localStorage.removeItem('myRole')
      window.localStorage.removeItem('asEmail')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('role')

      return resolve()
    })
  },
  checkAuth: () => {
    return new Promise((resolve, reject) => {
      const token = window.localStorage.getItem('token')

      if (token) {
        try {
          const decodedToken = decodeJwt(token)

          if (decodedToken.exp < new Date().getTime() / 1000) {
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('role')
            // eslint-disable-next-line
            return reject({ redirectTo: '/login' })
          }
        } catch (e) {
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('role')
          // eslint-disable-next-line
          return reject({ redirectTo: '/login' })
        }

        return resolve()
      }

      window.localStorage.removeItem('token')
      window.localStorage.removeItem('role')
      // eslint-disable-next-line
      return reject({ redirectTo: '/login' })
    })
  },
  getPermissions: () => {
    return new Promise((resolve, reject) => {
      const role = window.localStorage.getItem('role')

      if (role && role >= 5) {
        return resolve(role)
      }

      return reject(new Error('no_permissions'))
    })
  },
  checkError: (error) => {
    const status = error.status

    if (status === 401 || status === 403) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('role')
      // eslint-disable-next-line
      return Promise.reject()
    }
    return Promise.resolve()
  }
}

export default auth
