import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  FunctionField
} from 'react-admin'

const validateName = [required(), minLength(1)]

const LocationFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
    <TextInput source='city' alwaysOn />
  </Filter>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
)

export const LocationList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<LocationFilter />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='city' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const LocationShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
      <TextField source='street' />
      <TextField source='zipcode' />
      <TextField source='city' />
      <ReferenceField label='contactPerson' children='contactPerson' reference='users' source='contactPersonId'>
        <FunctionField render={record => `${`${record.firstName} `}${record.lastName}`} />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export const LocationEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput fullWidth source='name' validate={validateName} />
      <TextInput fullWidth source='street' />
      <TextInput fullWidth source='zipcode' />
      <TextInput fullWidth source='city' />
      <ReferenceInput
        source='contactPersonId'
        reference='users'
        matchSuggestion={() => true}
        perPage={100}
      >
        <AutocompleteInput optionText={record => record.firstName || record.lastName ? `${`${record.firstName} `}${record.lastName}` : ''} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const LocationCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth source='name' validate={validateName} />
      <TextInput fullWidth source='street' />
      <TextInput fullWidth source='zipcode' />
      <TextInput fullWidth source='city' />
      <ReferenceInput
        source='contactPersonId'
        reference='users'
        matchSuggestion={() => true}
        perPage={100}
      >
        <AutocompleteInput optionText={record => record.firstName || record.lastName ? `${`${record.firstName} `}${record.lastName}` : ''} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
