import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  DeleteButton
} from 'react-admin'

const validateName = [required(), minLength(1)]

const MeasurementFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
  </Filter>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
)

export const MeasurementList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<MeasurementFilter />}>
    <Datagrid>
      <TextField source='name' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const MeasurementShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
    </SimpleShowLayout>
  </Show>
)

export const MeasurementEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput fullWidth source='name' validate={validateName} />
    </SimpleForm>
  </Edit>
)

export const MeasurementCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth source='name' validate={validateName} />
    </SimpleForm>
  </Create>
)
