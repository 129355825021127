import React from 'react'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { createTheme } from '@material-ui/core/styles'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import PersonIcon from '@material-ui/icons/Person'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import LabelIcon from '@material-ui/icons/Label'
import WarningIcon from '@material-ui/icons/Warning'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import KitchenIcon from '@material-ui/icons/Kitchen'
import AssignmentIcon from '@material-ui/icons/Assignment'

import englishMessages from './messages/english'
import authProvider from './providers/auth'
import dataProvider from './providers/data'
import { DocumentShow, DocumentList } from './models/document'
import { DocumentTypeShow, DocumentTypeList, DocumentTypeEdit, DocumentTypeCreate } from './models/document-type'
import { RestaurantShow, RestaurantList, RestaurantEdit, RestaurantCreate } from './models/restaurant'
import { MenuShow, MenuList, MenuEdit } from './models/menu'
import { AllergyShow, AllergyList, AllergyEdit, AllergyCreate } from './models/allergy'
import { TaskShow, TaskList, TaskEdit, TaskCreate } from './models/task'
import { LabelShow, LabelList, LabelEdit, LabelCreate } from './models/label'
import { MeasurementShow, MeasurementList, MeasurementEdit, MeasurementCreate } from './models/measurement'
import { IngredientShow, IngredientList, IngredientEdit, IngredientCreate } from './models/ingredient'
import { LocationShow, LocationList, LocationEdit, LocationCreate } from './models/location'
import { DocumentValueNameShow } from './models/document-value-name'
import { DocumentValueShow } from './models/document-value'
import { FeaturedImageShow } from './models/featured-image'
import { UserShow, UserList, UserEdit, UserCreate } from './models/user'

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en')

const App = () => {
  const theme = createTheme({
    palette: {}
  })
  return (
    <Admin
      title=''
      dataProvider={dataProvider}
      theme={theme}
      authProvider={authProvider}
      i18nProvider={{
        translate: (key, options) => {
          const keys = key.split('.')
          let value = englishMessages

          for (const keyKey in keys) {
            const keyVal = keys[keyKey]
            if (!value[keyVal] && key) {
              console.log(`Missing translation string ${key}`)
              break
            }
            value = value[keyVal]
          }

          return i18nProvider.translate(key, options)
        },
        changeLocale: i18nProvider.changeLocale,
        getLocale: i18nProvider.getLocale
      }}
      disableTelemetry
    >
      <Resource name='users' show={UserShow} list={UserList} edit={UserEdit} create={UserCreate} icon={PersonIcon} />
      <Resource name='documents' show={DocumentShow} list={DocumentList} icon={InsertDriveFileIcon} />
      <Resource name='document-value-names' show={DocumentValueNameShow} icon={InsertDriveFileIcon} />
      <Resource name='document-values' show={DocumentValueShow} icon={InsertDriveFileIcon} />
      <Resource name='featured-images' show={FeaturedImageShow} icon={InsertDriveFileIcon} />
      <Resource name='document-types' show={DocumentTypeShow} list={DocumentTypeList} edit={DocumentTypeEdit} create={DocumentTypeCreate} icon={InsertDriveFileIcon} />
      <Resource name='restaurants' show={RestaurantShow} list={RestaurantList} edit={RestaurantEdit} create={RestaurantCreate} icon={RestaurantIcon} />
      <Resource name='menus' show={MenuShow} list={MenuList} edit={MenuEdit} icon={MenuBookIcon} />
      <Resource name='allergies' show={AllergyShow} list={AllergyList} edit={AllergyEdit} create={AllergyCreate} icon={WarningIcon} />
      <Resource name='tasks' show={TaskShow} list={TaskList} edit={TaskEdit} create={TaskCreate} icon={AssignmentIcon} />
      <Resource name='labels' show={LabelShow} list={LabelList} edit={LabelEdit} create={LabelCreate} icon={LabelIcon} />
      <Resource name='locations' show={LocationShow} list={LocationList} edit={LocationEdit} create={LocationCreate} icon={LocationOnIcon} />
      <Resource name='measurements' show={MeasurementShow} list={MeasurementList} edit={MeasurementEdit} create={MeasurementCreate} icon={OfflineBoltIcon} />
      <Resource name='ingredients' show={IngredientShow} list={IngredientList} edit={IngredientEdit} create={IngredientCreate} icon={KitchenIcon} />
    </Admin>
  )
}

export default App
