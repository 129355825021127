import React from 'react'
import {
  TextField,
  Show,
  SimpleShowLayout
} from 'react-admin'

export const FeaturedImageShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='url' />
      <TextField source='alt' />
    </SimpleShowLayout>
  </Show>
)
