import React from 'react'
import {
  List,
  Datagrid,
  TextInput,
  Edit,
  SimpleForm,
  EditButton,
  Create,
  Filter,
  TextField,
  Show,
  required,
  minLength,
  SimpleShowLayout,
  ShowButton,
  Toolbar,
  SaveButton,
  DeleteButton,
  Tab,
  TabbedShowLayout,
  NumberField,
  ReferenceField,
  NumberInput,
  ReferenceInput,
  SelectInput
} from 'react-admin'

const validateName = [required(), minLength(1)]

const IngredientFilter = props => (
  <Filter {...props}>
    <TextInput source='name' alwaysOn />
  </Filter>
)

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton />
  </Toolbar>
)

export const IngredientList = props => (
  <List bulkActionButtons={false} {...props} perPage={25} filters={<IngredientFilter />}>
    <Datagrid>
      <TextField source='name' />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
)

export const IngredientShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TabbedShowLayout>
        <Tab label='ingredient'>
          <TextField source='name' />
          <TextField source='imageUrl' />
          <ReferenceField source='measurementId' reference='measurements'>
            <TextField source='name' />
          </ReferenceField>
        </Tab>
        <Tab label='ingredientPrice'>
          <NumberField source='ingredientPrice.price' />
          <NumberField source='ingredientPrice.purchasePrice' />
        </Tab>
      </TabbedShowLayout>
    </SimpleShowLayout>
  </Show>
)

export const IngredientEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TabbedShowLayout style={{ width: '100%' }}>
        <Tab label='ingredient'>
          <TextInput fullWidth source='name' validate={validateName} />
          <TextInput fullWidth source='imageUrl' />
          <ReferenceInput source='measurementId' reference='measurements'>
            <SelectInput optionText='name' />
          </ReferenceInput>
        </Tab>
        <Tab label='ingredientPrice'>
          <NumberInput source='ingredientPrice.price' />
          <NumberInput source='ingredientPrice.purchasePrice' />
        </Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Edit>
)

export const IngredientCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput fullWidth source='name' validate={validateName} />
      <TextInput fullWidth source='imageUrl' />
    </SimpleForm>
  </Create>
)
