import React from 'react'
import {
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField
} from 'react-admin'

export const DocumentValueShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='value' />
      <ReferenceField reference='document-value-names' source='documentValueNameId'>
        <TextField source='name' />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
