import { stringify } from 'query-string'

const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/v1`

const requestBuilder = async (url, method = 'GET', body) => {
  const token = window.localStorage.getItem('token')

  const response = await window.fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body
  })

  const responseData = await response.json()

  if (!response.ok) {
    return {
      status: response.status,
      message: responseData.message
    }
  }

  return {
    data: [],
    ...responseData,
    status: response.status,
    statusText: response.statusText,
    ok: response.ok
  }
}

const dataProvider = {
  getList: async (resource, params) => {
    try {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        order: JSON.stringify([[field, order]]),
        page: JSON.stringify({
          page: page - 1,
          limit: perPage
        }),
        filter: JSON.stringify(params.filter)
      }

      const response = await requestBuilder(`${apiUrl}/${resource}?${stringify(query)}`)

      if (!response.ok) {
        return Promise.reject(response)
      }

      return {
        data: response.data,
        total: response.pageInfo.total
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  getOne: async (resource, params) => {
    try {
      const response = await requestBuilder(`${apiUrl}/${resource}/${params.id}`)

      if (!response.ok) {
        return Promise.reject(response)
      }

      return {
        data: response.data[0]
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  getMany: async (resource, params) => {
    try {
      const response = await requestBuilder(`${apiUrl}/${resource}`)

      if (!response.ok) {
        return Promise.reject(response)
      }

      return response
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  getManyReference: async (resource, params) => {
    try {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        order: JSON.stringify([[field, order]]),
        page: JSON.stringify({
          page: page - 1,
          limit: perPage
        }),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id
        })
      }

      const response = await requestBuilder(`${apiUrl}/${resource}?${stringify(query)}`)

      if (!response.ok) {
        return Promise.reject(response)
      }

      return {
        data: response.data,
        total: response.pageInfo.total
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  create: async (resource, params) => {
    try {
      const body = JSON.stringify(params.data)
      const response = await requestBuilder(`${apiUrl}/${resource}`, 'POST', body)

      if (!response.ok) {
        return Promise.reject(response)
      }

      return {
        data: response.data[0]
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  update: async (resource, params) => {
    try {
      const body = JSON.stringify(params.data)
      const response = await requestBuilder(`${apiUrl}/${resource}/${params.id}`, 'PUT', body)

      if (!response.ok) {
        return Promise.reject(response)
      }

      const updatedResponse = await requestBuilder(`${apiUrl}/${resource}/${params.id}`)

      if (!updatedResponse.ok) {
        return Promise.reject(updatedResponse)
      }

      return {
        data: updatedResponse.data[0]
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  delete: async (resource, params) => {
    try {
      const response = await requestBuilder(`${apiUrl}/${resource}/${params.id}`, 'DELETE')

      if (!response.ok) {
        return Promise.reject(response)
      }

      return response
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  },
  deleteMany: async (resource, params) => {
    try {
      const query = {
        filter: JSON.stringify({ id: params.ids })
      }
      const response = await requestBuilder(`${apiUrl}/${resource}?${stringify(query)}`, 'DELETE')

      if (!response.ok) {
        return Promise.reject(response)
      }

      return response
    } catch (err) {
      console.log(err)
      return Promise.reject(new Error('Unhandled error'))
    }
  }
}

export default dataProvider
