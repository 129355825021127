import React from 'react'
import {
  List,
  Filter,
  Datagrid,
  TextField,
  Show,
  Tab,
  TabbedShowLayout,
  ReferenceManyField,
  ShowButton,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  FunctionField,
  ChipField
} from 'react-admin'

const DocumentFilter = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source='documentTypeId'
        reference='document-types'
        alwaysOn
      >
        <SelectInput />
      </ReferenceInput>
    </Filter>
  )
}

export const DocumentList = props => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={<DocumentFilter />}
    >
      <Datagrid>
        <TextField source='user.email' />
        <FunctionField
          source='createdAt'
          render={record => {
            const createdAt = new Date(record.createdAt)
            return `${createdAt.toISOString().slice(0, 10)} ${createdAt.toISOString().slice(11, 16)}`
          }}
        />
        <ReferenceField reference='document-types' source='documentTypeId' link='show'>
          <ChipField source='name' />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  )
}

export const DocumentShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label='document'>
        <TextField source='url' />
        <ReferenceField reference='document-types' source='documentTypeId' link='show'>
          <TextField source='name' />
        </ReferenceField>
      </Tab>
      <Tab label='document-value' path='document-values'>
        <ReferenceManyField reference='document-values' target='documentId' label='' sort={{ field: 'value', order: 'DESC' }}>
          <Datagrid>
            <TextField source='value' />
            <ReferenceField reference='document-value-names' source='documentValueNameId' link='show'>
              <TextField source='name' />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
